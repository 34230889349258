import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FaDumbbell, FaFileDownload, FaBullseye } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

const Step = ({ title, description, icon: Icon, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, delay: index * 0.2 }
    }
  };

  return (
    <motion.div 
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      className="bg-white rounded-2xl border border-gray-200 flex flex-col items-center p-6 md:p-8"
    >
      <Icon className="text-5xl md:text-7xl text-logoRed mb-4 md:mb-6" />
      <h3 className="text-xl md:text-2xl font-bold mb-2 md:mb-4 font-inter text-gray-900 text-center">{title}</h3>
      <p className="text-base md:text-lg text-gray-700 font-fira text-center">{description}</p>
    </motion.div>
  );
};

const TextSection = ({ title, description, isLast }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      className={`text-center md:text-left ${!isLast ? 'mb-6 md:mb-8 pb-6 md:pb-8 border-b border-gray-400' : ''}`}
    >
      <h3 className="text-xl md:text-2xl font-bold mb-2 text-gray-800">{title}</h3>
      <p className="text-sm md:text-base font-fira text-gray-600 font-semibold">{description}</p>
    </motion.div>
  );
};

const CombinedSection = () => {
  const parallaxRef = useRef(null);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (parallaxRef.current) {
        parallaxRef.current.style.backgroundPositionY = -(scrolled * 0.4) + 'px';
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <section id='how-it-works'
      ref={parallaxRef}
      className="py-12 font-inter relative bg-[#f8f8f8]"
      style={{
        backgroundImage: 'url("/images/pattern-gym.png")',
        backgroundRepeat: 'repeat',
        backgroundSize: '750px',
        backgroundAttachment: 'scroll',
      }}
    >
      {/* Top Divider */}
      <div className="absolute top-0 left-0 w-full overflow-hidden" style={{ height: 'auto' }}>
        <img 
          src="/images/gradient-bg.png" 
          alt="Top divider" 
          className="w-full md:h-full md:object-cover min-h-screen"
        />
      </div>

      <div className="relative z-10 max-w-6xl mx-auto px-4 md:px-6 mb-10">
        <div
          className="md:text-5xl text-3xl mb-12 text-center font-fira text-white"
        >
          Peak Fitness Has Never Been So Easy To{' '}
          <span className="relative inline-block group">
            Achieve
            <span className="absolute bottom-0 left-0 w-full -z-10 h-2 bg-logoRed transform -skew-x-12 group-hover:skew-x-12 transition-transform duration-300"></span>
          </span>{' '}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 mb-16 md:mb-20">
          <Step
            index={0}
            icon={FaBullseye}
            title="Step 1: Input Your Fitness Goals"
            description="Tell us about your fitness goals, and we'll help you achieve them with the most efficiency."
          />
          <Step
            index={1}
            icon={FaDumbbell}
            title="Step 2: Use the Builder or AI"
            description="Create a workout plan using our builder or let the AI design one for you."
          />
          <Step
            index={2}
            icon={FaFileDownload}
            title="Step 3: Download Your Plan"
            description="Download your workout PDF to your device, or share it via email."
          />
        </div>

        <div className="flex flex-col lg:flex-row items-center">
  <div className="lg:w-1/2 pr-0 md:pr-8"
  style={{textShadow: "-1px 0px 10px #e4e4e4"}}>
    <motion.h2 
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={headerVariants}
      className="text-3xl md:text-4xl mb-8 md:mb-12 text-black font-fira text-center md:text-left"
      style={{textShadow: "1px 0px 2px rgba(228,228,228,1)"}}>
      No More Hassles, Just{' '}
      <span className="relative inline-block group">
        Results
        <span className="absolute bottom-0 left-0 w-full -z-10 h-2 bg-logoRed transform -skew-x-12 group-hover:skew-x-12 transition-transform duration-300"></span>
      </span>
    </motion.h2>
    <div className=''
      style={{textShadow: "1px 0px 2px rgba(228,228,228,1)"}}>
      <TextSection
        title="Big Results For Small Cost"
        description="Get a professional workout plan for a fraction of the cost."
      />
      <TextSection
        title="Extensive Exercise Library"
        description="1200+ exercises to choose from. Whatever you're looking for, we have."
      />
      <TextSection
        title="AI Personalized Plans"
        description="Forget expensive cookie-cutter plans that do not take your needs in consideration. We provide you a workout plan 100% tailored to your need."
        isLast
      />
    </div>
  </div>
  <motion.div 
    ref={ref}
    animate={controls}
    initial="hidden"
    variants={imageVariants}
    className="lg:w-1/2 mt-8 lg:mt-0"
  >
    <img 
      src="/images/girl-workout.png" 
      alt="Girl working out" 
      className="h-80 md:w-full md:h-auto rounded-lg"
      style={{ filter: "drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.8))" }}
    />
  </motion.div>
</div>
      </div>
      
      {/* Bottom Divider */}
      <div className="absolute bottom-0 left-0 w-full overflow-hidden" style={{ height: '70px' }}>
        <svg 
          className="relative block w-full h-full"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            fill="#000000"
          />
        </svg>
      </div>
    </section>
  );
};

export default CombinedSection;