import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import AnimatedLogoRows from './AnimatedLogoRows';
import { FaInstagram, FaTwitter, FaTiktok, FaChevronDown, FaSpinner } from 'react-icons/fa';

const WaitlistMailingList = () => {
  const [email, setEmail] = useState('');
  const [socialHandle, setSocialHandle] = useState('');
  const [socialPlatform, setSocialPlatform] = useState('Instagram');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState('')

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const socialPlatforms = [
    { name: 'Instagram', icon: FaInstagram, color: '#E1306C' },
    { name: 'X', icon: FaTwitter, color: '#1DA1F2' },
    { name: 'TikTok', icon: FaTiktok, color: '#69C9D0' },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter a valid email address.');
      return;
    }
  
    setIsLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch('https://sft6abzf44xaodk5zpuszwfjge0hqtnm.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          socialHandle,
          socialPlatform,
        }),
      });
  
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setIsSubmitted(true);
        setEmail('');
        setSocialHandle('');
        setSocialPlatform('');
        setSuccessMessage('Successfully joined the waitlist!');
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialSelect = (platform) => {
    setSocialPlatform(platform);
    setIsDropdownOpen(false);
  };

  const SocialIcon = socialPlatforms.find(p => p.name === socialPlatform)?.icon || FaInstagram;

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <section id="waitlist" className="py-10 px-8 bg-black text-white text-center">
      <motion.div 
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={containerVariants}
        className="max-w-4xl mx-auto"
      >
        <motion.h2 
          variants={itemVariants}
          className="text-3xl md:text-5xl mb-6 font-fira"
        >
          Join the Waitlist
        </motion.h2>
        <motion.p 
          variants={itemVariants}
          className="text-xl md:text-2xl text-gray-300 mb-12 font-fira"
        >
          Be the first to know when PDFTrainer launches!
        </motion.p>
        <motion.form 
          onSubmit={handleSubmit} 
          className="flex flex-col items-center gap-4"
          variants={containerVariants}
        >
          <motion.input
            variants={itemVariants}
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full max-w-md px-4 py-3 bg-gray-800 text-white border-2 border-gray-700 rounded-md text-lg focus:border-logoRed focus:outline-none transition-colors duration-300"
            required
          />
          <motion.div variants={itemVariants} className="w-full max-w-md relative">
            <input
              type="text"
              placeholder="Social media handle (optional)"
              value={socialHandle}
              onChange={(e) => setSocialHandle(e.target.value.startsWith('@') ? e.target.value : '@' + e.target.value)}
              className="w-full pr-20 pl-4 py-3 bg-gray-800 text-white border-2 border-gray-700 rounded-md text-lg focus:border-logoRed focus:outline-none transition-colors duration-300"
            />
            <div 
              ref={dropdownRef}
              className="absolute right-0 top-0 bottom-0 flex items-center"
            >
              <button
                type="button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="h-full flex items-center bg-gray-700 border-2 border-l-0 border-gray-700 rounded-r-md focus:outline-none transition-colors duration-300 hover:bg-gray-600"
              >
                <div className="px-2 border-r border-gray-600">
                  <SocialIcon className="text-2xl text-logoRed" />
                </div>
                <div className="px-2">
                  <FaChevronDown className="text-sm text-gray-400" />
                </div>
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 top-full mt-1 w-40 bg-gray-700 rounded-md shadow-lg z-10">
                  {socialPlatforms.map((platform) => (
                    <button
                      key={platform.name}
                      onClick={() => handleSocialSelect(platform.name)}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-600 focus:outline-none transition-colors duration-300"
                    >
                      <platform.icon className="inline-block mr-2 text-logoRed" />
                      {platform.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
          <motion.button
            variants={itemVariants}
            type="submit"
            disabled={isLoading}
            className={`px-8 py-3 bg-logoRed text-white rounded-full text-lg cursor-pointer transition-colors duration-300 hover:bg-red-700 font-semibold flex items-center justify-center ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isLoading ? (
              <>
              Join Waitlist
                <FaSpinner className="ml-3 animate-spin mr-2" />
                
  
              </>
            ) : (
              'Join Waitlist'
            )}
          </motion.button>
        </motion.form>
        {isSubmitted && successMessage && (
          <div className="mt-6 text-green-400 text-xl">
            {successMessage}
          </div>
        )}
        {error && (
          <motion.p 
            className="mt-6 text-red-400 text-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </motion.p>
        )}
      </motion.div>
      
      <AnimatedLogoRows />
    </section>
  );
};

export default WaitlistMailingList;