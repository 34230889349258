import React from 'react';
import { motion } from 'framer-motion';

const LogoRow = ({ direction }) => {
  const logos = Array(10).fill(null); // Adjust number as needed for proper spacing
  
  return (
    <div className="relative w-full overflow-hidden h-6 md:h-12 my-2"> {/* Container with hidden overflow */}
      <motion.div
        className="flex absolute whitespace-nowrap"
        initial={{ x: direction === 'left' ? '0%' : '-50%' }}
        animate={{ x: direction === 'left' ? '-50%' : '0%' }}
        transition={{
          x: {
            repeat: Infinity,
            repeatType: 'loop',
            duration: 30,
            ease: 'linear',
          },
        }}
      >
        {logos.concat(logos).map((_, index) => (
          <div key={index} className="flex-shrink-0 mx-3 md:mx-6">
            <img src="/images/logo-text-banner.png" alt="PDFTrainer" className="h-4 md:h-8 object-contain" />
          </div>
        ))}
      </motion.div>
      {/* Gradient overlay for fade effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black pointer-events-none"></div>
    </div>
  );
};

const AnimatedLogoRows = () => {
  return (
    <div className="mt-16 bg-black"> {/* Adjust mt (margin-top) as needed */}
      <LogoRow direction="left" />
      <LogoRow direction="right" />
      <LogoRow direction="left" />
    </div>
  );
};

export default AnimatedLogoRows;