import React from 'react';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import HowItWorksComponent from './components/HowItWorks';
import WaitlistMailingList from './components/WaitlistMailingList';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
// import PricingPlans from './components/PricingPlans';
// import Testimonials from './components/Testimonials';
// Add more imports for other sections (Testimonials, Pricing, FAQ, etc.)

const App = () => {
  return (
    <>

      <HeroSection />
      <AboutSection />
      <HowItWorksComponent />
      <WaitlistMailingList />
      <FAQ />
      <Footer />
    </>
  );
};

export default App;
