import React from 'react';



const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 px-4 text-center">
      <p className="text-sm text-gray-400 mt-4">
        © 2024 PDFTrainer. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;